<!--
 * @Author: YeJson
 * @Date: 2021-07-31 13:17:16
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-07-09 10:54:28
 * @Description: 文件描述
 * @FilePath: \YeesharyMusicc:\Users\a1808\Desktop\Prrogram\my\web_xh_vue\src\components\sub_msgs\msgs_cards.vue
-->
<template>
  <div>
    <a-row :gutter="20">
        <template v-for="(one_card,one_card_index) in information_datas.cards">
          <a-col v-if="!currentParentId||one_card.parent_id==currentParentId" :key="one_card_index" :span="12" style="margin-bottom: 12px;height: 350px;">
              <a-card :title="one_card.card_title" style="height: 100%;" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#FFFFFF, #DCF1FD)'}">
                <span slot="extra" style="cursor: pointer;" @click="toMsgsList(one_card.type_id)">+ 更多</span>
                <a-list size="small" bordered :data-source="subList(one_card.news_list)">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <span style="float: left; margin-right: 10px;font-size: 20px;">·</span>
                    <router-link :to="`/home/msgs/msgs_detail?id=${item.id}`" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 70%;" v-text="item.title">
                    </router-link>
                    <span v-text=" item.created_at.substr(0,10)"></span>
                  </a-list-item>
                </a-list>
              </a-card>
          </a-col>
        </template>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'msgs_list',
  created () {
    const _that = this
    _that.$axios.get('/info/getIndexNewsData').then((res) => {
      const resData = res.data.data
      Object.keys(resData).forEach((oneParentKey) => {
        const oneParent = resData[oneParentKey]
        oneParent.sons.forEach((son) => {
          this.information_datas.cards.push({
            parent_id: oneParentKey,
            type_id: son.id,
            card_title: son.type_name,
            news_list: son.news
          })
        })
      })
    }).catch((err) => {
      console.log(err)
    })
  },
  watch: {
    $route: {
      handler () {
        this.currentParentId = this.$route.query.id || ''
      },
      immediate: true
    }
  },
  data () {
    return {
      currentParentId: '',
      information_datas:
        {
          cards: []
        }
    }
  },
  methods: {
    subList (list) {
      return list.slice(0, 5)
    },
    toMsgsList (typeId) {
      this.$router.push(`/home/msgs/msgs_list/?id=${typeId}`)
    }
  }
}
</script>

<style>
</style>
