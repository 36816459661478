<template>
  <div class="w">
    <!-- mod_copyright  -->
    <div class="mod_copyright">
      <p class="mod_copyright_links">
        关于我们 | 联系我们 | 联系客服 | 友情链接 | English Site | Contact U
      </p>
      <p class="mod_copyright_info">
        地址：厦门市湖里区金山街道高林西里17-101号 邮编：361000 电话：5720328 邮箱: <a href="xhdcia2021@163.com">xhdcia2021@163.com</a>  <br>
        <a style="line-height: 32px;" href="https://beian.miit.gov.cn/"
           target="_blank">备案号: 闽ICP备2021010858号-1</a>

           <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020602002237" style="line-height: 32px;margin-left: 32px;">
             闽公网安备 35020602002237号</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>
