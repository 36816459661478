import Vue from 'vue'
import { Carousel, Spin, Badge, Empty, Popover, Steps, Affix, Tabs, TreeSelect, Statistic, Timeline, PageHeader, Tooltip, Form, Comment, List, Card, Breadcrumb, Result, Divider, Descriptions, InputNumber, Upload, message, DatePicker, Select, FormModel, Modal, Drawer, Space, Pagination, Button, Layout, Menu, Icon, Avatar, Table, Tag, Input, Row, Col, Switch, Dropdown } from 'ant-design-vue'

Vue.use(Spin)
Vue.use(Badge)
Vue.use(Empty)
Vue.use(Carousel)
// Vue.use(Menu.MenuItemGroup)
// Vue.use(Menu.Item)
// Vue.use(Menu.ItemGroup)
// Vue.use(Menu.SubMenu)
Vue.use(Popover)
Vue.use(Steps)
Vue.use(Steps.Step)
Vue.use(Affix)
Vue.use(Tabs)
Vue.use(TreeSelect)
Vue.use(Dropdown)
Vue.use(Statistic)
Vue.use(Timeline)
Vue.use(Card)
Vue.use(List)
Vue.use(Comment)
Vue.use(Form)
Vue.use(Form.Item)
Vue.use(Tooltip)
Vue.use(PageHeader)
Vue.component(Pagination.name, Pagination)
Vue.component(Button.name, Button)
Vue.component(Button.Group.name, Button.Group)
Vue.component(Layout.name, Layout)
Vue.component(Layout.Header.name, Layout.Header)
Vue.component(Layout.Sider.name, Layout.Sider)
Vue.component(Layout.Content.name, Layout.Content)
Vue.component(Layout.Footer.name, Layout.Footer)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Menu.ItemGroup.name, Menu.ItemGroup)
Vue.component(Icon.name, Icon)
Vue.component(Avatar.name, Avatar)
Vue.component(Table.name, Table)
Vue.component(Tag.name, Tag)
Vue.component(Input.name, Input)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(Space.name, Space)
Vue.use(Drawer)
Vue.component(Drawer.name, Drawer)
Vue.use(Modal)
Vue.component(Modal.name, Modal)
Vue.component(Input.Search.name, Input.Search)
Vue.component(Descriptions.name, Descriptions)
Vue.component(Descriptions.Item.name, Descriptions.Item)
Vue.use(FormModel)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Result)
Vue.use(Divider)
Vue.use(Descriptions)
Vue.use(Descriptions.Item)
Vue.use(InputNumber)
Vue.use(Breadcrumb)
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
