import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 封装使用了拦截器的axios
import my_axios from './my_axios/my_axios.js'

import './plugins/ant-design-vue.js'
import 'ant-design-vue/dist/antd.css'
// import axios from 'axios'
// import x2js from 'x2js'

// Vue.prototype.$x2js = x2js
Vue.prototype.iLoading = false
Vue.prototype.changeLoading = function () {
  this.$set(this, 'iLoading', !Vue.prototype.iLoading)
  // Vue.prototype.iLoading = !Vue.prototype.iLoading
}
// 配置请求baseUrl
// axios.defaults.baseURL = 'http://localhost:80'
// api请求头中设置token
// axios.interceptors.request.use(config => {
//   config.headers.Authorization = sessionStorage.getItem('token')
//   return config
// })
// Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.prototype.$axios = my_axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
