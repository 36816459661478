<!--
 * @Author: 叶锦全
 * @Date: 2022-04-30 09:46:43
 * @LastEditors: 叶锦全
 * @LastEditTime: 2023-06-29 20:09:40
 * @Description: file content
-->
<template>
  <div class="xiaxin_msgs">
    <sub-layout-one :navList="navList" :defaultSelect="defaultSelect" @itemSelect="itemSelect"></sub-layout-one>
  </div>
</template>
<script>
import subLayoutOne from './common/subLayoutOne.vue'
export default {
  components: { subLayoutOne },
  data() {
    return {
      currentId: 0,
      navList: {
        title: '会员之家',
        data: [{
          id: 0,
          name: '全部',
          icon: 'appstore',
          show_sub: false,
          path: '/home/member_home/association_member?level_name=全部'
        }]
      },
      defaultSelect: { name: '全部' }
    }
  },
  created() {
    const _that = this
    const level_name = this.$route.query.level_name
    this.$axios.get('/info/getAllMembers').then((res) => {
      Object.keys(res.data.data).forEach((item, index) => {
        const item_value = res.data.data[item]
        _that.navList.data.push({
          id: index + 1,
          icon: 'appstore',
          name: item_value.level_name,
          show_sub: false,
          path: `/home/member_home/association_member?level_name=${item_value.level_name}`,
          sons: []
        })
      })
      _that.navList.data.unshift()
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    itemSelect(item) {
      console.log(item)
      this.currentId = item.id
      this.defaultSelect = this.navList.data[this.currentId]
      // this.$router.push(`/home/about/${item.id}`)
    }
  }
}
</script>
<style scoped lang="less">
::v-deep {
  .ant-table-pagination.ant-pagination {
    width: 100%;
    text-align: center;
  }
}
</style>
