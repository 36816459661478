<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-14 12:37:08
-->
<template>
  <div class="about_detail">
    <div style="padding: 10px 20px;">
      <div class="title" style="text-align:center">检测咨询部</div>
      <div class="oneRow">
<div>1、</div>根据区建设主管部门的要求，配合对发生生产事故的施工企业开展安全生产评价及咨询工作；
      </div>
      <div class="oneRow">
<div>2、</div>负责建立技术咨询服务专家库，为协会会员开展各种技术咨询服务，对施工中重大技术疑难问题，组织工程技术论证；
      </div>
      <div class="oneRow">
<div>3、</div>开展技术研讨、学术讲座，指导企业施工中采用新材料、新设备、新技术、新工艺，组织开展绿色施工示范工程活动等；
      </div>
      <div class="oneRow">
<div>4、</div>组织各类旨在加强会员企业安全防范风险意识，提高建筑施工安全技术，提高检测工程质量的讲座和交流会
      </div>
      <div class="oneRow">
<div>5、</div>负责检测合同的报备工作，配合区建设主管部门开展对检测机构日常服务工作；
      </div>
      <div class="oneRow">
<div>6、</div>完成协会领导交办的其他工作。
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      url: '',
      numPages: null,
      htmlContent: [
        '协会简介',
        '协会章程',
        '领导机构',
        '协会任务'
      ]
    }
  },
  created () {
  },
  components: {
  },
  mounted () {
  },
  computed: {
    routerComputed () {
      return '协会简介'
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.about_detail {
  color: #333333;
  .title {
    color: #333333;
    font-size: 28px;
    margin-bottom: 24px;
  }
  .oneRow {
    display: flex;
    margin-bottom: 18px;
    color: #666666;
    font-size: 20px;
  }
}
</style>
