<template>
  <div class="xiaxin_notice_modal">
    <!-- 通知弹窗 -->
    <a-modal v-model="infoModalVisible"
             class="notice_modal"
             width="50%"
             :centered="true"
             :maskClosable="false"
             :footer="null">
      <template slot="title">
        <div class="notice_modal_title">
          通知
        </div>
      </template>
      <div class="notices_wrap">
        <a-timeline mode="left"
                    class="notice_modal_list">
          <a-timeline-item class="notice_modal_list_item"
                           v-for="notice in noticeList"
                           :key="notice.id">
            <span class="notice_item_time">{{notice.time}}</span>
            <div class="notice_item_content"
                 @click="handleNotice(notice)">{{notice.content}}</div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'noticeModal',
  data () {
    return {
      infoModalVisible: true, // 通知弹窗是否可见
      noticeList: [
        {
          id: '1',
          content: '这是一条测试通知,这是一条测试通知,这是一条测试通知,这是一条测试通知',
          time: '2020-10-1'
        }, {
          id: '2',
          content: '这是一条测试通知',
          time: '2020-10-1'
        }, {
          id: '3',
          content: '这是一条测试通知',
          time: '2020-10-1'
        }, {
          id: '4',
          content: '这是一条测试通知',
          time: '2020-10-1'
        }
      ]
    }
  },
  created () {
    // todo 后端获取信息
  },
  methods: {
    handleNotice (notice) {
      console.log(notice)
    }
  }
}
</script>
<style lang="less" scoped>
.xiaxin_notice_modal {
  .notice_modal {
    width: 40%;
    max-width: 700px;
  }
}
.notice_modal_title {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}
.notices_wrap {
  padding-left: 30px;
}
.notice_modal_list {
  font-size: 16px;
  .notice_modal_list_item {
    font-size: 18px;
    .notice_item_time {
      font-size: 14px;
      color: #ccc;
    }
    .notice_item_content {
      cursor: pointer;
      color: #777;
      padding-left: 5px;
    }
  }
}
</style>
