import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/index.vue'
// import Content from '../components/content.vue'
import Home from '../components/home.vue'
import Msgs from '../components/msgs.vue'
import MemBerHome from '../components/member_home.vue'
import Layout from '../components/layout.vue'
import Error from '../components/error.vue'
import MsgsCards from '../components/sub_msgs/msgs_cards.vue'
import MsgsDetail from '../components/sub_msgs/msgs_detail.vue'
import MsgsList from '../components/sub_msgs/msgs_list.vue'
import MSgsRanking from '../components/sub_msgs/msgs_ranking.vue'
import AssociationMember from '../components/sub_msgs/association_member.vue'

// 工程案例
import EngineCase from '../components/engine_case.vue'
import EngineCaseCards from '../components/engineCase/engine_case_cards.vue'
import EngineCaseDetail from '../components/engineCase/engine_case_detail.vue'

// 关于协会
import About from '../components/about.vue'
import AboutDetail from '../components/about/detail.vue'
import Consititution from '../components/about/subdetail/consititution.vue'
import DownloadCenter from '../components/downloadCenter.vue'
import DownloadDetail from '../components/downloadCenter/index.vue'

// 联系我们
import contactUs from '../components/contactUs.vue'
import contactUsDetail from '../components/contactUs/detail.vue'

// 检测考核平台

import testConsult from '../components/testConsult.vue'
import testConsultDetail from '../components/testConsult/detail.vue'

// 办事大厅
import DownloadHome from '../components/download_home.vue'
import membershipProcess from '../components/sub_download/membership_process.vue'

// 信息公开
import publicInfoHome from '../components/publicInfoHome.vue'
import DownloadList from '../components/publicInfo/download_list.vue'
Vue.use(VueRouter)
// 路由重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function myPush(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    name: '/',
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        name: 'index',
        path: '/index',
        component: Index
      }, {
        path: '/home',
        component: Home,
        children: [{
          name: 'index',
          path: '/home/index',
          redirect: '/'
        }, {
          // 信息公开
          path: '/home/public_info',
          component: publicInfoHome,
          children: [
            {
              alias: '',
              name: 'download_list',
              path: 'download_list',
              component: DownloadList
            }
          ]
        }, {
          // 新闻中心
          path: '/home/msgs',
          component: Msgs,
          children: [
            // 信息公开部分的主页，即显示卡片列表
            {
              name: 'msgs_detail',
              path: 'msgs_detail',
              component: MsgsDetail
            },
            {
              name: 'msgs_cards',
              path: 'msgs_cards',
              component: MsgsCards
            },
            {
              name: 'msgs_list',
              path: '/home/msgs/msgs_list',
              component: MsgsList
            },
            {
              name: 'msgs_ranking',
              path: '/home/msgs/msgs_ranking',
              component: MSgsRanking
            }
          ]
        }, {
          // 工程案例
          name: 'engine_case',
          path: '/home/engine_case',
          component: EngineCase,
          children: [
            {
              name: 'engine_case',
              path: 'engine_case_cards',
              alias: '',
              component: EngineCaseCards
            },
            {
              name: 'case_detail',
              path: 'engine_case_detail',
              component: EngineCaseDetail
            }
          ]
        }, {
          // 会员之家
          name: 'member',
          path: '/home/member_home',
          component: MemBerHome,
          children: [
            {
              alias: '',
              name: 'association_member',
              path: 'association_member',
              component: AssociationMember
            }
          ]
        }, {
          // 办事大厅 下载文件
          name: 'download',
          path: '/home/downloadCenter',
          component: DownloadCenter,
          children: [
            {
              name: 'download1',
              path: '/home/downloadCenter/',
              component: DownloadDetail
            }
          ]
        }, {
          // 关于协会
          name: 'about',
          path: '/home/about',
          component: About,
          children: [
            {
              name: 'about_detail',
              // path: 'detail/:id',
              path: 'detail/',
              component: AboutDetail,
              children: [
                // {
                //   name: 'consititution',
                //   path: '1',
                //   component: Error
                // },
                {
                  name: 'association',
                  path: 'association',
                  component: Consititution
                }
                // {
                //   name: 'consititution',
                //   path: '3',
                //   component: Error
                // },
                // {
                //   name: 'consititution',
                //   path: '4',
                //   component: Error
                // }
              ]
            }
          ]
        }, {
          name: 'contactUs',
          path: '/home/contactUs',
          component: contactUs,
          children: [
            {
              alias: '', /// home/contactUs
              name: 'contactUs_detail',
              path: 'detail',
              component: contactUsDetail
            }
          ]
        }, {
          name: 'testConsult',
          path: '/home/testConsult',
          component: testConsult,
          children: [
            {
              alias: '', /// home/testConsult
              name: 'testConsult_detail',
              path: 'detail',
              component: testConsultDetail
            }
          ]
        }, {
          path: '/home/office_hall',
          component: DownloadHome,
          children: [
            {
              alias: '',
              name: 'membership_process',
              path: 'membership_process',
              component: membershipProcess
            }
          ]
        }]
      }
    ]
  }, {
    path: '*',
    redirect: '/error/404'
  }, {
    path: '/error/404',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
