<!--
 * @Author: YeJson
 * @Date: 2021-07-18 09:25:31
 * @LastEditors: 叶锦全
 * @LastEditTime: 2023-06-30 00:18:47
 * @Description: 文件描述
 * @FilePath: \YeesharyMusicc:\Users\a1808\Desktop\Prrogram\my\web_xh_vue\src\components\home.vue
-->
<template>
  <div class="w home_layout">
    <div class="home_layout_nav">
      你所在的位置:
      <a-breadcrumb class="breadcrumb" :routes="routes">
        <template slot="itemRender" slot-scope="{ route }">
          <router-link v-if="route.index != routes.length - 1" :to="`${basePath}/${route.path}`">
            {{ route.name }}
          </router-link>
          <span v-else>{{ route.name }}</span>
        </template>
      </a-breadcrumb>
    </div>
    <!-- 这里是除首页外，主要内容改变的路由，即面包屑一下的部分 -->
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  created() {
    const currentPath = this.$route.path
    const matchedRoute = this.routes_rules.find((oneRouteRule) => {
      return currentPath.match(oneRouteRule.path)
    })
    this.routes.push(matchedRoute)
  },
  watch: {
    // 监听$route路由当路由改变时，面包屑根据路由动态改变
    $route() {
      this.routes.pop()
      const currentPath = this.$route.path
      const matchedRoute = this.routes_rules.find((oneRouteRule) => {
        return currentPath.match(oneRouteRule.path)
      })
      this.routes.push({ ...matchedRoute, index: this.routes.length })
    }
  },
  data() {
    // const { lang } = this.$route.params
    return {
      basePath: '/home',
      // 这里是参与匹配的数组，只要地址匹配到了item.path,item.name就会被推入面包屑
      routes_rules: [
        {
          path: 'public_info',
          name: '信息公开'
        },
        {
          path: 'msgs',
          name: '新闻中心'
        },
        {
          path: 'member_home',
          name: '会员之家'
        },
        {
          path: 'about',
          name: '关于协会'
        },
        {
          path: 'office_hall',
          name: '办事大厅'
        },
        {
          path: 'engine_case',
          name: '工程案例'
        },
        {
          path: 'contactUs',
          name: '联系我们'
        }
      ],
      routes: [
        // 这是面包屑的首部
        {
          path: 'index',
          name: '首页'
        }
      ]
    }
  }
}
</script>
<style scoped>
.home_layout {
  background: white;
  min-height: calc(100vh - 320px);
}

.home_layout_nav {
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
  padding-left: 10px;
}

.home_layout .breadcrumb {
  display: inline;
  padding-left: 5px;
  font-size: 14px;
  line-height: 45px;
}
</style>
