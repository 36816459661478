<template>
  <div class="w xiaxin_home_page clearfix">

    <div class="home_page_left_wrap">
      <a-row>
        <!-- <aside> -->
        <a-col :md="24"
               :lg="24">
          <a-card :bordered="false">
            <div class="swiper-container"
                 id="swiper1">
              <div class="swiper-wrapper">
                  <div  class="swiper-slide" v-for="(oneCarousel) in carouselList" :key="oneCarousel">
                    <img :src="oneCarousel"
                        class="slide_img"
                       style="object-fit: cover;"
                        :alt="oneCarousel">
                  </div>
              </div>
              <!-- 如果需要分页器 -->
              <div class="swiper-pagination"></div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="12"
               :lg="12">
          <a-card :bordered="false"
                  :headStyle="{'borderBottom':'none'}"
                  :bodyStyle="{'paddingTop':'0'}">
            <div slot="title"
                 style="border-bottom:2px solid #1e50ae;text-align:center"
                 class="clearfix">
              <h2 class="fl style_blue"
                  style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">新闻中心</h2>
              <a href="#/home/msgs/msgs_list?id=2"
                 class="fr item_header_more">MORE</a>
            </div>
            <a-list size="small"
                    :md="5"
                    :split="false"
                    :bordered="false"
                    :data-source="listDataLeft">
              <a-list-item slot="renderItem"
                           class="list_item"
                           style="line-height:30px;font-size:16px"
                           slot-scope="item">
                <a-icon type="tag"
                        style="color: #1e50ae;padding-right:5px"></a-icon>
                <a class="fl item_text"
                   :href="'#/home/msgs/msgs_detail?id='+item.id"
                   style="font-size:16px;width:calc(100% - 120px);" :title="item.title">{{ item.title }}</a>
                <span class="fr"
                      style="font-size: 14px;">{{ item.created_at.substring(0,10) }}</span>
              </a-list-item>
              <!-- <div slot="header"
                   style="border-bottom:2px solid #1e50ae;text-align:center"
                   class="clearfix">
                <h2 class="fl style_blue"
                    style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">Header</h2>
                <a href="#"
                   class="fr item_header_more">MORE</a>
              </div> -->
            </a-list>
          </a-card>
        </a-col>
        <a-col :md="12"
               :lg="12">
          <a-card :bordered="false"
                  :headStyle="{'borderBottom':'none'}"
                  :bodyStyle="{'paddingTop':'0'}">
            <div slot="title"
                 style="border-bottom:2px solid #1e50ae;text-align:center"
                 class="clearfix">
              <h2 class="fl style_blue"
                  style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">信息公开</h2>
              <a href="#/home/public_info"
                 class="fr item_header_more">MORE</a>
            </div>
            <a-list size="small"
                    :md="5"
                    :split="false"
                    :bordered="false"
                    :data-source="listDataRight">
              <a-list-item slot="renderItem"
                           class="list_item"
                           style="line-height:30px;font-size:16px"
                           slot-scope="item">
                <a-icon :type="item.file_url.indexOf('.pdf')!==-1?'file-pdf':(item.file_url.indexOf('.doc')!==-1||item.file_url.indexOf('.docx')!==-1?'file-word':'file')"
                        style="color: #1e50ae;padding-right:5px"></a-icon>
                <a class="fl item_text"
                   @click.prevent="downloadFile(item.file_url)"
                   style="font-size:16px;width:calc(100% - 120px);" :title="item.file_name" v-text="item.file_name"></a>
                <span class="fr"
                      style="font-size: 14px;">{{ item.created_at.substring(0,10) }}</span>
              </a-list-item>
              <!-- <div slot="header"
                   style="border-bottom:2px solid #1e50ae;text-align:center"
                   class="clearfix">
                <h2 class="fl style_blue"
                    style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">Header</h2>
                <a href="#"
                   class="fr item_header_more">MORE</a>
              </div> -->
            </a-list>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24"
               :lg="24">
          <a-card :bordered="false"
                  :headStyle="{'borderBottom':'none'}"
                  :bodyStyle="{'paddingTop':'0'}">
            <div slot="title"
                 style="border-bottom:2px solid #1e50ae;text-align:center"
                 class="clearfix">
              <h2 class="fl style_blue"
                  style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">荣誉资质</h2>
              <a href="#"
                 class="fr item_header_more">MORE</a>
            </div>
            <div class="new-swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide"
                     style="text-align:center">
                  <img src="../../static/image/pic (1).jpg"
                       style="width:50%"
                       alt="">
                </div>
                <div class="swiper-slide"
                     style="text-align:center">
                  <img src="../../static/image/pic (2).jpg"
                       style="width:50%"
                       alt="">
                </div>
                <div class="swiper-slide"
                     style="text-align:center">
                  <img src="../../static/image/pic (3).jpg"
                       style="width:50%"
                       alt="">
                </div>
                <div class="swiper-slide"
                     style="text-align:center">
                  <img src="../../static/image/pic (4).jpg"
                       style="width:50%"
                       alt="">
                </div>
                <div class="swiper-slide"
                     style="text-align:center">
                  <img src="../../static/image/pic (5).jpg"
                       style="width:50%"
                       alt="">
                </div>
              </div>
              <!-- Add Pagination -->
              <!-- <div class="swiper-pagination"></div> -->
            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24"
               :lg="24">
          <a-card :bordered="false"
                  :headStyle="{'borderBottom':'none'}"
                  :bodyStyle="{'paddingTop':'0'}">
            <div slot="title"
                 style="border-bottom:2px solid #1e50ae;text-align:center"
                 class="clearfix">
              <h2 class="fl style_blue"
                  style="border-bottom:2px solid #1e50ae;display: inline-block;margin:0">工程案例</h2>
              <a href="#"
                 class="fr item_header_more">MORE</a>
            </div>
            <a-card title=""
                    :bordered="false">
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (1).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center"
                           :hoverable="false">
                <img src="../../static/image/pic (2).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (3).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (4).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (5).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (6).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (7).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
              <a-card-grid style="width:25%;text-align:center">
                <img src="../../static/image/pic (8).jpg"
                     style="width:100%"
                     alt="">
              </a-card-grid>
            </a-card>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="home_page_right_wrap">
      <!-- 登录 -->
      <!-- <div class="login_wrap">
        <div class="login_wrap_title">会员登录</div>
        <div class="login_wrap_logo">logo</div>
        <a-form class="login_wrap_form"
                :form="loginForm">
          <a-form-item :label-col="formItemLayout.labelCol"
                       class="login_form_item"
                       :wrapper-col="formItemLayout.wrapperCol"
                       label="用户名">
            <a-input placeholder="用户名">
              <a-icon slot="prefix"
                      type="user"
                      style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item :label-col="formItemLayout.labelCol"
                       :wrapper-col="formItemLayout.wrapperCol"
                       class="login_form_item"
                       label="密码">
            <a-input placeholder="密码"
                     type="password">
              <a-icon slot="prefix"
                      type="lock"
                      style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-item>
          <a-form-item :label-col="formTailLayout.labelCol"
                       :wrapper-col="formTailLayout.wrapperCol"
                       class="login_form_item">
            <a-button type="primary"
                      :ghost="true"
                      @click="handleLogin">
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div> -->
      <!-- 快速入口 -->
      <div class="quick_entrance">
        <ul class="quick_entrance_list">
          <li class="quick_entrance_list_item">
            <a href="http://lbyf.snjsrc.com/"
               target="_blank">考核培训平台</a>
          </li>
          <li class="quick_entrance_list_item">
            <a href="https://xhdcia.hyebid.cn" target="_blank">招投标管理平台</a>
          </li>
          <li class="quick_entrance_list_item">
            <a @click="toTestConsult">检测咨询平台</a>
          </li>
          <li class="quick_entrance_list_item">
            <a href="#">集采平台</a>
          </li>
          <li class="quick_entrance_list_item">
            <a href="http://www.huli.gov.cn/">政府文件公开平台</a>
          </li>
        </ul>
      </div>
      <!-- 网上互动 -->
      <a-card :bordered="false"
              class="interaction_wrap"
              :headStyle="{'borderBottom':'none','padding':0}"
              :bodyStyle="{'padding':0}">
        <div slot="title"
             class="interaction_wrap_title clearfix">
          <h2 class="fl style_blue"
              style="">网上互动</h2>
        </div>
        <a-list size="small"
                :md="5"
                :split="false"
                :bordered="false"
                :data-source="listDataLeft">
          <a-list-item class="interaction_wrap_list_item">
            <!-- <a-icon type="tag"
                    style="color: #1e50ae"></a-icon> -->
            <a class="fl item_text"
               @click="toContactUs"
               style="font-size:16px;width:100%;">咨询投诉</a>
          </a-list-item>
          <a-list-item class="interaction_wrap_list_item">
            <!-- <a-icon type="tag"
                    style="color: #1e50ae"></a-icon> -->
            <a class="fl item_text"
               @click="toContactUs"
               style="font-size:16px;width:100%;">建议提案</a>
          </a-list-item>
          <a-list-item class="interaction_wrap_list_item">
            <!-- <a-icon type="tag"
                    style="color: #1e50ae"></a-icon> -->
            <a class="fl item_text"
               @click="toContactUs"
               style="font-size:16px;width:100%;">网上调查</a>
          </a-list-item>
          <a-list-item class="interaction_wrap_list_item">
            <!-- <a-icon type="tag"
                    style="color: #1e50ae"></a-icon> -->
            <a class="fl item_text"
               @click="toContactUs"
               style="font-size:16px;width:100%;">在线访谈</a>
          </a-list-item>
        </a-list>
      </a-card>
    </div>
    <!-- 通知弹窗 -->
    <info-modal v-if="showDialog"></info-modal>
  </div>
</template>
<script>
import Swiper from 'swiper/bundle'
import infoModal from './common/infoModal'
import 'swiper/swiper-bundle.css'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 8, offset: 6 }
}
export default {
  components: { infoModal },
  data () {
    return {
      showDialog: false,
      loginForm: {},
      formItemLayout,
      formTailLayout,
      mySwipper: null,
      listDataLeft: [],
      listDataRight: [],
      carouselList: []
    }
  },
  created () {
    // 获取左边信息公开列表
    this.$axios.get('/info/getNewsByTypeID', {
      params: {
        id: 2
      }
    }).then((res) => {
      this.listDataLeft = res.data.data.news_data.slice(0, 6)
    }).catch((err) => {
      console.log(err)
    })
    // 获取右边文件下载列表
    this.$axios.get('/file/getFiles').then((res) => {
      this.listDataRight = res.data.data.slice(0, 6)
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  },
  mounted () {
    // 获取轮播图列表
    this.$axios.get('/carousel/getCarousel').then((res) => {
      this.carouselList = res.data.data
      this.$nextTick(() => {
        // 获取到数据偶初始化swiper
        this.initMySwiper()
      })
    }).catch((err) => {
      console.log(err)
    })
    this.newSwiper = new Swiper('.new-swiper-container', {
      autoplay: true, // 等同于以下设置
      loop: true,
      slidesPerView: 5,
      spaceBetween: 30,
      pagination: {
        el: '.new-swiper-container .swiper-pagination',
        clickable: true
      }
    })
  },
  methods: {
    toTestConsult () {
      this.$router.push({
        path: '/home/testConsult/detail'
      })
    },
    toContactUs () {
      this.$router.push({
        path: '/home/contactUs/detail'
      })
    },
    initMySwiper () {
      this.mySwipper = new Swiper('#swiper1', {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        autoplay: true, // 等同于以下设置
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-container .swiper-pagination'
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-container .swiper-button-next',
          prevEl: '.swiper-container .swiper-button-prev'
        }

      })
    },
    // 登录
    handleLogin () {

    },
    // 下载文件
    downloadFile (url) {
      console.log(url)
      const fileName = decodeURI(url.replace('https://yicixin-blog-image.oss-cn-shenzhen.aliyuncs.com/web_xh/', ''))
      this.$axios.get(url, { params: {}, responseType: 'blob' }, {
        responseType: 'blob'
      }).then(function (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.xiaxin_home_page {
  background: white;
  .home_page_left_wrap {
    float: left;
    width: 80%;
  }
  .home_page_right_wrap {
    float: right;
    width: 20%;
    padding-right: 10px;
    margin-top: 24px;
    .login_wrap {
      height: 300px;
      width: 100%;
      border: 2px solid #1e50ae;
      border-radius: 2px;
      .login_wrap_title {
        color: white;
        font-size: 22px;
        line-height: 40px;
        font-weight: bold;
        text-align: center;
        background: #1e50ae;
      }
      .login_wrap_logo {
        text-align: center;
        font-size: 18px;
        line-height: 50px;
      }
      .login_wrap_form {
        margin-top: 20px;
        .login_form_item {
          margin-bottom: 10px;
        }
      }
    }
    .quick_entrance {
      .quick_entrance_list {
        li:first-child {
          margin-top: 0;
        }
        .quick_entrance_list_item {
          display: block;
          background: #ccc;
          line-height: 70px;
          font-size: 24px;
          font-weight: bold;
          margin: 10px 0;
          border-radius: 5px;
          background: #1e50ae;
          text-align: center;
          a {
            color: white;
            // &:hover {
            //   color: #1e50ae;
            // }
          }
        }
      }
    }
    // 网上互动
    .interaction_wrap {
      .interaction_wrap_title {
        border-bottom: 2px solid #1e50ae;
        text-align: center;
        h2 {
          border-bottom: 2px solid #1e50ae;
          display: inline-block;
          margin: 0;
          font-size: 18px;
        }
      }
      .interaction_wrap_list_item {
        text-align: center;
        background: #eee;
        margin: 5px 0;
        a {
          color: rgba(0, 0, 0, 0.65);
          &:hover {
            color: #1e50ae;
          }
        }
      }
    }
  }
  .swiper-container {
    width: 100%;
    // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
    // --swiper-navigation-color: #00ff33; /* 单独设置按钮颜色 */
    --swiper-navigation-size: 20px; /* 设置按钮大小 */
  }
}
</style>
