<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-14 18:52:13
-->
<template>
  <div class="about_detail">
    <div style="padding: 10px 20px;">
      <div class="oneRow">
        <span class="title">入会条件：</span>
        <span class="context"
          >独立法人机构、注册经营地址所属湖里片区、纳税所属湖里片区的企业</span
        >
      </div>
      <div class="oneRow">
        <span class="title">申请材料：</span>
        <span class="context"
          >入会申请书、营业执照复印件、资质证明复印件、法人身份证复印件（以上材料均需盖公章）</span
        >
      </div>
      <div class="oneRow">
        <span class="title">附件：</span>
        <span class="context">
          <a
            style="margin-left: 10px;"
            href="https://web-xh.oss-accelerate.aliyuncs.com/1662716344123604000_附件2：湖里区建设行业协会单位会员申请表.doc"
          >
            湖里区建设行业协会单位会员申请表</a
          ></span
        >
        <!-- <a style="margin-left: 10px;" @click.prevent="download()"> 下载</a></span> -->
      </div>
      <div class="oneRow" style="margin-bottom: 16px;">
        <span class="context"
          >将以上材料送至湖里区建设行业协会（厦门市湖里区禾山路266号联谊大厦515室），待协会过会审核通过后通知缴交会费后正式入会。</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: "",
      numPages: null,
      htmlContent: ["协会简介", "协会章程", "领导机构", "协会任务"]
    };
  },
  created() {},
  components: {},
  mounted() {},
  computed: {
    routerComputed() {
      return "协会简介";
    }
  },
  methods: {
    download() {
      window.open(
        "https://web-xh.oss-accelerate.aliyuncs.com/1662716344123604000_附件2：湖里区建设行业协会单位会员申请表.doc"
      );
    }
  }
};
</script>
<style lang="less" scoped>
.about_detail {
  .oneRow {
    margin-bottom: 24px;
    display: flex;
    font-size: 18px;
    .title {
      color: #666666;
    }
    .context {
      flex: 1;
      color: #333333;
    }
  }
}
</style>
