<template>
  <div>
    <div class="xiaxin_header header w">
      <!-- logo -->
      <div class="logo">
        <h1>
          <img class="xiaxin_logo" src="../../assets/logo5.png" />
          <a href="#/index" title="logo">logo</a>
        </h1>
      </div>
      <!-- search -->
      <div class="search">
        <input type="text" class="text" placeholder="请搜索内容...">
        <button class="btn">搜索</button>
      </div>
      <!-- <div class="extend">
        <a href="#">设为首页</a>
        <div class="divider"></div>
        <a href="#">收藏本站</a>
      </div> -->
    </div>
    <!-- nav start -->
    <nav class="nav">
      <!-- 导航 -->
      <div class="w">
        <ul class="navitems clearfix w">
          <li>
            <router-link to="/index">首页</router-link>
          </li>
          <li>
            <router-link to="/home/public_info">信息公开</router-link>
          </li>
          <li>
            <router-link to="/home/about/detail/association">关于协会</router-link>
          </li>
          <li>
            <router-link to="/home/msgs/msgs_cards?id=1">新闻中心</router-link>
          </li>
          <li>
            <router-link to="/home/office_hall/membership_process">办事大厅</router-link>
          </li>
          <li>
            <router-link to="/home/member_home/association_member?level_name=全部">会员之家</router-link>
          </li>
          <li>
            <router-link to="/home/engine_case">工程案例</router-link>
          </li>
          <li>
            <router-link to="/home/contactUs">联系我们</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!-- nav end  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      navs: [
        {
          name: '首页',
          url: '/index'
        }, {
          name: '信息公开',
          url: '/home/msgs'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.xiaxin_header {
  height: 250px;
}

.xiaxin_logo {
  height: 50px;
}

.extend {
  float: right;
  line-height: 40px;

  .divider {
    display: inline-block;
    height: 15px;
    width: 2px;
    background: #ccc;
    margin: -3px 3px;
    padding-top: 3px;
  }

  a {
    color: #3e3e3e;

    &:hover {
      color: #1e50ae;
    }
  }
}
</style>
