<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2023-06-29 22:11:28
-->
<template>
  <div>
    <sub-layout-one :navList="navList" :defaultSelect="navList.data[currentId]"></sub-layout-one>
  </div>
</template>
<script>
import subLayoutOne from './common/subLayoutOne.vue'
export default {
  components: { subLayoutOne },
  data() {
    return {
      currentId: 0,
      navList: {
        title: '下载中心',
        path: '',
        data: [{
          id: 1,
          name: '文件下载',
          path: '/home/about/detail/1',
          icon: 'appstore'
        }, {
          id: 2,
          name: '表格下载',
          path: '/home/about/detail/association',
          icon: 'appstore'
        }]
      }
    }
  },
  methods: {
    itemSelect(item) {
      console.log(item)
      this.currentId = item.id
      // this.$router.push(`/home/about/${item.id}`)
    }
  }
}
</script>
<style lang="less" scoped></style>
