<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-15 12:14:07
-->
<template>
    <!-- <a-card :title="page_name" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#E4F5FE, #DCF1FD)'}"> -->
      <a-table :columns="columns" bordered :pagination="table_data.length > 15?{ pageSize: 15 }:false" :data-source="table_data">
      </a-table>
    <!-- </a-card> -->
</template>

<script>
export default {
  data () {
    return {
      // page_name: '协会成员',
      table_data: [],
      table_data_format_all: [],
      table_data_no_format_all: [],
      level_name: '',
      columns: [
        {
          title: '企业名称',
          dataIndex: 'member_name',
          align: 'center'
        },
        // {
        //   title: '设计资质',
        //   dataIndex: 'design_qualification',
        //   align:'center'
        // },
        // {
        //   title: '监理资质',
        //   dataIndex: 'supervision_qualification',
        //   align:'center'
        // },
        // {
        //   title: '施工资质',
        //   dataIndex: 'construct_qualification',
        //   align:'center'
        // },
        {
          title: '身份',
          dataIndex: 'level_name',
          align: 'center'
        }
      ]
    }
  },
  watch: {
    $route (new_val, old_val) {
      const level_name = this.$route.query.level_name
      this.table_data = this.getTableDataNoFormatAll(level_name)
    }
  },
  created () {
    const _that = this
    this.$axios.get('/info/getAllMembers').then((res) => {
      // 有指定身份
      this.table_data_no_format_all = res.data.data
      // 所有成员
      Object.values(res.data.data).forEach((item_1st) => {
        item_1st.members_infos.forEach((item_2nd) => {
          item_2nd.key = item_2nd.member_id
          this.table_data_format_all.push({
            key: item_2nd.member_id,
            member_name: item_2nd.member_name,
            level_name: item_2nd.level_name
            // supervision_qualification:item_2nd.supervision_qualification,
            // design_qualification:item_2nd.design_qualification,
            // construct_qualification:item_2nd.construct_qualification
          })
        })
      })
      const level_name = this.$route.query.level_name
      this.table_data = this.getTableDataNoFormatAll(level_name)
    }).catch((err) => {
      console.log(err)
    })
  },
  mounted () {

  },
  methods: {
    getTableDataNoFormatAll (level_name) {
      if (!level_name || level_name === '全部') {
        return this.table_data_format_all
      }
      return Object.values(this.table_data_no_format_all).find((item) => {
        return item.level_name === level_name
      }).members_infos
    }
  }
}
</script>

<style>
</style>
