<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-08 16:40:36
-->
<template>
  <div class="about_detail">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
      url: '',
      numPages: null,
      htmlContent: [
        '协会简介',
        '协会章程',
        '领导机构',
        '协会任务'
      ]
    }
  },
  created () {
  },
  components: {
  },
  mounted () {
  },
  computed: {
    routerComputed () {
      return '协会简介'
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.about_detail {
}
</style>
