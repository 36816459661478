import axios from 'axios'
// import router from '../router/index.js'
// 请求数据格式转换
import qs from 'qs'

// 跨域发送凭证
// axios.defaults.withCredentials = true;
// 请求拦截器
axios.interceptors.request.use((request) => {
  // request.baseURL = 'http://47.97.109.8:8088'
  request.baseURL = 'https://www.xhdcia.cn/back'

  // form data提交数据 方法一 qs转换
  request.data = qs.stringify(request.data, { arrayFormat: 'repeat' })// arrayFormat：设置发送数组类型数据时的格式
  // request.contentType = "application/json;charset=UTF-8"
  // request.contentType = "multipart/form-data"

  return request
})
// 响应拦截器
axios.interceptors.response.use(async (response) => {
  return response
})
export default axios
