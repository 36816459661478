<!--
 * @Author: 叶锦全
 * @Date: 2022-09-10 16:35:32
 * @LastEditors: 叶锦全
 * @LastEditTime: 2023-06-29 21:53:18
 * @Description: file content
-->
<template>
  <div style="width: 100%;padding: 0 24px;" v-if="case_data !== null">
    <div style="border-bottom: dotted 1px #C1C1C1;">
      <h2 class="news_title" v-text="case_data.title">

      </h2>
      <p class="news_info">
        发文时间：[{{ case_data.created_at }}]
      </p>
    </div>
    <div class="news_content" v-html="case_data.content">

    </div>
    <!-- <div class="last_next last">
      <span>上一篇：</span>
      <router-link to="#">厦门企业用工状况与对策研究课题座谈会 ——建筑劳务企业专场</router-link>
    </div>
    <div class="last_next next">
      <span>下一篇：</span>
      <router-link to="#">厦门企业用工状况与对策研究课题座谈会 ——建筑劳务企业专场</router-link>
    </div> -->
    <!-- <a-card title="相关资讯" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#E4F5FE, #DCF1FD)'}">
      <a-list size="small" bordered :data-source="list">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <router-link to="/home/msgs/msgs_detail" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
            {{ item }}
          </router-link>
          <span>[2021-07-18]</span>
        </a-list-item>
      </a-list>
    </a-card> -->
  </div>
</template>

<script>
export default {
  name: 'caseContent',
  data() {
    return {
      case_id: 0,
      case_data: null
    }
  },
  created() {
    const _that = this
    this.case_id = this.$route.query.id
    _that.$axios.get('/info/projectCase/get', {
      params: {
        id: _that.case_id
      }
    }).then((res) => {
      console.log(res)
      _that.case_data = res.data.data
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {

  }
}
</script>

<style>
.news_title {
  text-align: center;
  padding: 16px 0px 0px 0px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  font-family: 微软雅黑;
  color: #0090dd;
}

.news_info {
  text-align: center;
  line-height: 18px;
  border: 0px solid #011113;
  font-size: 12px;
  letter-spacing: 0px;
}

.news_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
}

.last_next {
  font-size: 16px;
}

.last {
  margin-top: 48px;
}

.next {
  margin-bottom: 24px;
}
</style>
