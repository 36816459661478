<template>
  <div class="xiaxin_sub_layout_one clearfix">
    <!-- 信息公开页面 -->
    <div class="sub_layout_left info_nav">
      <div class="layout_nav_title" v-if="navList.title">
        {{ navList.title }}
      </div>
      <ul class="layout_nav_list">
        <template v-for="item in navList.data">
          <li class="layout_nav_list_item" :key="item.id" @click="handleClickItem(item)">
            <a-icon v-if="item.icon" class="list_item_icon" :type="item.icon"></a-icon>
            <span href="#" class="list_item_content">{{ item.name }}</span>
          </li>
          <li class="layout_nav_list_item" :key="item.id + '' + sub_item.id" @click="handleClickSubItem(sub_item)"
            v-for="sub_item in item.sons" v-show="item.show_sub">
            <a-icon style="margin-left: 10px;" class="list_item_icon" type="right"></a-icon>
            <span href="#" class="list_item_content">{{ sub_item.name }}</span>
          </li>
        </template>
      </ul>
    </div>
    <div class="sub_layout_right">
      <div class="sub_layout_right_title">
        {{ currentSelect.name }}
      </div>
      <router-view style="width: 100%;"></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentSelect: {}
    }
  },
  props: {
    navList: {
      type: [Object],
      default: () => { }
    },
    defaultSelect: {
      type: [Number, String, Object],
      default: ''
    }
  },
  watch: {
    $route(newVal, oldVal) {
      console.log(newVal, oldVal)
      if (newVal.path === '/home/member_home/association_member') {
        this.currentSelect = { name: '全部' }
      }
    },
    defaultSelect(newVal) {
      console.log('defaultSelect', newVal)
      // this.$router.push(this.defaultSelect.path)
      // this.$set(this, 'currentSelect', newVal)
    }
  },
  created() {
    console.log(this.defaultSelect)
    if (this.defaultSelect) {
      this.$router.push(this.defaultSelect.path)
      this.$set(this, 'currentSelect', this.defaultSelect)
    }
    // this.currentSelect = this.defaultSelect
  },
  methods: {
    handleClickItem(item) {
      this.$emit('itemSelect', item)
      this.currentSelect = item
      item.show_sub = !item.show_sub
      this.$router.push(item.path)
    },
    handleClickSubItem(item) {
      this.$router.push(item.path)
    }
  }
}
</script>
<style lang="less">
.xiaxin_sub_layout_one {
  &.clearfix::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  .sub_layout_left {
    float: left;
    width: 300px;
    padding: 10px;

    .layout_nav_title {
      width: 100%;
      font-size: 30px;
      line-height: 120px;
      color: white;
      font-weight: bold;
      background: #1e50ae;
      text-align: center;
    }

    .layout_nav_list {
      .layout_nav_list_item {
        font-size: 16px;
        color: black;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ccc;
        padding: 0 5px 0 10px;
        background: white;
        margin-top: -1px;
        transition: all 0.3s;

        .list_item_icon {
          font-size: 18px;
          color: #1e50ae;
          padding: 0 10px;
        }

        &:hover {
          cursor: pointer;
          color: white;
          background: #1e50ae;
        }

        &.active {
          color: white;
          background: #1e50ae;
        }
      }
    }
  }

  .sub_layout_right {
    float: right;
    width: calc(~'100% - 300px');
    padding: 10px 0;

    .sub_layout_right_title {
      color: white;
      line-height: 40px;
      font-size: 18px;
      padding-left: 20px;
      background: #1e50ae;
      margin-bottom: 12px;
    }
  }
}
</style>
