<template>
  <a-result status="404" title="404" style="padding-top: 200px;" sub-title="对不起,您当前访问的页面不存在">
    <template #extra>
      <a-button type="primary" @click="$router.push('/index')">
        返回首页
      </a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped>
#app {
  background-image: unset;
}
 </style>
