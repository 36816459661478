<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-08 22:00:21
-->
<template>
    <div>
      <a-card title="协会文件" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#E4F5FE, #DCF1FD)'}">
        <a-list size="small" bordered :data-source="list"  :pagination="pagination">
          <a-list-item slot="renderItem" slot-scope="item">
            <a href="#" @click.prevent="download(item.file_url)" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" v-text="item.file_name">

            </a>
            <div style="width: 160px;">
              <span v-text="`[${item.created_at.substring(0,10)}]`"></span>
              <!-- <a style="float: right;margin-left: 10px;" href="#" :download="item.file_url"> -->
              <a style="float: right;margin-left: 10px;" @click.prevent="download(item.file_url)">
                下载
              </a>
              <a style="float: right;" v-if='item.file_url.indexOf(".pdf")!==-1' target="_blank" :href="item.file_url">
                预览
              </a>
            </div>
          </a-list-item>
        </a-list>
      </a-card>
    </div>
</template>

<script>
export default {
  name: 'download_list',
  props: {
    // list_class_name:{
    //   type:String,
    //   default:""
    // }
  },
  data () {
    return {
      list_class_name: '',
      current_page: 1,
      list: [],
      pagination: {
        pageSize: 15
      }
    }
  },
  created () {
    // 获取文件列表
    this.$axios.get('/file/getFiles').then((res) => {
      this.list = res.data.data
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    download (url) {
      const fileName = decodeURI(url.replace('https://yicixin-blog-image.oss-cn-shenzhen.aliyuncs.com/web_xh/', ''))
      this.$axios.get(url, { params: {}, responseType: 'blob' }, {
        responseType: 'blob'
      }).then(function (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>

<style scoped>
::v-deep .ant-pagination {
  text-align: center;
}
</style>
