<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-09-08 18:54:43
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-14 13:06:23
-->
<template>
  <div>
    <sub-layout-one :navList="navList" :defaultSelect="navList.data[currentId]" @itemSelect="itemSelect"></sub-layout-one>
  </div>
</template>
<script>
import subLayoutOne from './common/subLayoutOne.vue'
export default {
  components: { subLayoutOne },
  data () {
    return {
      currentId: 0,
      navList: {
        title: '检测咨询平台',
        path: '',
        data: [
          {
            id: 0,
            name: '检测咨询平台',
            path: '/home/testConsult/detail',
            icon: 'appstore',
            sons: [],
            show_sub: false
          }
        ]
      }
    }
  },
  methods: {
    itemSelect (item) {
      console.log(item)
      this.currentId = item.id
    }
  }
}
</script>
<style lang="less" scoped>
</style>
