<!--
 * @Author: 叶锦全
 * @Date: 2022-04-30 09:46:43
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-10 16:40:09
 * @Description: file content
-->
<template>
    <div>
       <a-card title="排名卡片" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#FFFFFF, #DCF1FD)'}">
          <a-table :pagination="false" :columns="columns" :data-source="data" align="center">

            </a-table>
        </a-card>
    </div>
</template>

<script>
export default {
  name: 'msgs_list',
  props: {
    list_class_name: {
      type: String,
      default: '政策法规'
    }
  },
  data () {
    return {
      data: [
        {
          key: '1',
          id: '10086',
          name: 'John Brown',
          company_type: '建筑工程'
        },
        {
          key: '2',
          id: '1008611',
          name: 'Jim Green',
          company_type: '建筑工程'
        },
        {
          key: '3',
          id: '10001',
          name: 'Joe Black',
          company_type: '建筑工程'
        },
        {
          key: '4',
          id: '10086',
          name: 'John Brown',
          company_type: '建筑工程'
        },
        {
          key: '5',
          id: '1008611',
          name: 'Jim Green',
          company_type: '建筑工程'
        },
        {
          key: '6',
          id: '10001',
          name: 'Joe Black',
          company_type: '建筑工程'
        }
      ],
      columns: [
        {
          dataIndex: 'key',
          key: 'key',
          title: '序号',
          align: 'center'
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: '会员号',
          align: 'center'
        },
        {
          dataIndex: 'company_type',
          key: 'company_type',
          title: '企业资质(主项)',
          align: 'center'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
