<template>
  <div style="height:100%">
    <!-- <svg width="1000"
         height="500">
      <filter id="surface">
        <feTurbulence type="fractalNoise"
                      baseFrequency='.95'
                      numOctaves="80"
                      result='noise' />
        <feDiffuseLighting in='noise'
                           lighting-color='#fff'
                           surfaceScale='1.4'
                           result="grind">
          <feDistantLight azimuth='500'
                          elevation='50' />
        </feDiffuseLighting>
        <feGaussianBlur in="grind"
                        stdDeviation=".6" />
      </filter>
    </svg> -->
    <!-- header start -->
    <header>
      <xiaxin-header></xiaxin-header>
    </header>
    <!-- header end -->
    <router-view></router-view>
    <!-- footer start -->
    <footer class="footer">
      <xiaxin-footer></xiaxin-footer>
    </footer>

    <!-- footer end -->
  </div>
</template>
<script>
import xiaxinHeader from './common/header.vue'
import xiaxinFooter from './common/footer.vue'
export default {
  components: { xiaxinHeader, xiaxinFooter },
  data () {
    return {

    }
  },
  created () {
  },
  mounted () {

  }
}
</script>

<style>
@import "../assets/css/base.css";
@import "../assets/css/common.css";
@import "../assets/css/index.css";
</style>
