<!--
 * @Author: YeJson
 * @Date: 2021-07-31 13:17:16
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-13 14:34:57
 * @Description: 文件描述
 * @FilePath: \YeesharyMusicc:\Users\a1808\Desktop\Prrogram\my\web_xh_vue\src\components\sub_msgs\msgs_cards.vue
-->
<template>
  <div>
    <a-row :gutter="20">
        <template v-for="(one_card,one_card_index) in information_datas">
          <a-col v-if="!currentParentId||one_card.parent_id==currentParentId" :key="one_card_index" :span="8" style="margin-bottom: 12px;">
              <a-card :title="one_card.title" style="height: 100%;" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#FFFFFF, #DCF1FD)'}">
                <div style="width:100%;aspect-ratio:4/3;cursor:pointer;">
                  <img style="width:100%;
                  height: 100%;" @click="toCaseDetail(one_card)" object-fit="cover" :src="one_card.cover"/>
                </div>
              </a-card>
          </a-col>
        </template>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'msgs_list',
  created () {
    const _that = this
    _that.$axios.get('/info/projectCase/getList').then((res) => {
      const resData = res.data.data
      this.information_datas = resData
    }).catch((err) => {
      console.log(err)
    })
  },
  watch: {
    $route: {
      handler () {
        this.currentParentId = this.$route.query.id || ''
      },
      immediate: true
    }
  },
  data () {
    return {
      currentParentId: '',
      information_datas: []
    }
  },
  methods: {
    toCaseDetail (item) {
      console.log(item)
      // this.$router.push(`/home/msgs/msgs_detail?id=${item.id}`)
      this.$router.push({
        path: `/home/engine_case/engine_case_detail?id=${item.id}`
        // path: '/home/msgs/msgs_detail?id=83'
      })
    }
  }
}
</script>

<style>
</style>
