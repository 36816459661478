<template>
  <div id="app"
       ref="app">
    <a-spin :spinning="spinning"
            :tip="tip">
      <router-view @changeSpining="changeSpining"></router-view>
    </a-spin>
  </div>

</template>

<script>

export default {
  name: 'app',
  data () {
    return {
      spinning: false,
      tip: '加载中...'
    }
  },
  methods: {
    changeSpining (tip) {
      this.spinning = !this.spinning
      this.tip = tip || ''
    }
  }
}
</script>

<style>
#app {
  height: 100%;
}
.ant-spin-nested-loading {
  height: 100%;
}
.ant-spin-container {
  height: 100%;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-spinning {
  max-height: 100%;
}
</style>
