<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-15 09:25:52
-->
<template>
  <div style="width: 100%;padding: 0 24px;" v-if="news_data!==null">
    <div style="border-bottom: dotted 1px #C1C1C1;">
      <h2 class="news_title" v-text="news_data.title">

      </h2>
      <p class="news_info">
        发文时间：[{{news_data.created_at}}]
      </p>
    </div>
    <div class="news_content" v-html="news_data.content">
</div>
  </div>
</template>

<script>
export default {
  name: 'NewsContent',
  data () {
    return {
      news_id: 0,
      news_data: null
    }
  },
  created () {
    const _that = this
    this.news_id = this.$route.query.id
    _that.$axios.get('/info/getNewsByID', {
      params: {
        id: _that.news_id
      }
    }).then((res) => {
      console.log(res)
      _that.news_data = res.data.data
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {

  }
}
</script>

<style>
.news_title {
  text-align: center;
  padding: 16px 0px 0px 0px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  font-family: 微软雅黑;
  color: #0090dd;
}
.news_info {
  text-align: center;
  line-height: 18px;
  border: 0px solid #011113;
  font-size: 12px;
  letter-spacing: 0px;
}
.news_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
}
.last_next {
  font-size: 16px;
}
.last {
  margin-top: 48px;
}
.next {
  margin-bottom: 24px;
}
</style>
