<!--
 * @Author: YeJson
 * @Date: 2021-07-31 13:17:16
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-07-09 15:19:47
 * @Description: 文件描述
 * @FilePath: \YeesharyMusicc:\Users\a1808\Desktop\Prrogram\my\web_xh_vue\src\components\sub_msgs\msgs_list.vue
-->
<template>
    <div>
      <a-card :title="list_class_name" :headStyle="{color:'#0064c2', fontWeight:'bold', backgroundImage: 'linear-gradient(#E4F5FE, #DCF1FD)'}">
        <a-list size="small" bordered :data-source="list" :pagination="true">
          <a-list-item slot="renderItem" slot-scope="item">
            <router-link :to="`/home/msgs/msgs_detail/?id=${item.id}`"
              style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
              v-text="item.title">
            </router-link>
            <span v-text="item.created_at.substring(0,10)"></span>
          </a-list-item>
        </a-list>
        <!-- <a-pagination style="margin-top: 20px; text-align: center;" v-model="current_page" :total="list.length" show-less-items /> -->
      </a-card>
    </div>
</template>

<script>
export default {
  name: 'msgs_list',
  props: {
    // list_class_name:{
    //   type:String,
    //   default:""
    // }
  },
  data () {
    return {
      list_class_name: '',
      current_page: 1,
      list: []
    }
  },
  created () {
    const id = this.$route.query.id
    this.$axios.get('/info/getNewsByTypeID',
      {
        params: {
          id
        }
      }
    ).then((res) => {
      this.list = res.data.data.news_data
      this.list_class_name = res.data.data.type_name
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {

  }
}
</script>

<style>
</style>
