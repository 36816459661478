<!--
 * @模块组件?公共组件: 组件中文名
 * @Comp: <组件名称>
 * @desc: 组件描述
 * @author: 叶锦全
 * @Date: 2022-07-05 09:01:29
 * @param: {Number} [paramName: notRequired?required] - 参数说明
 * @example: 调用示例
 * <compm :param='' @xxx=''></comp>
 * @LastEditors: 叶锦全
 * @LastEditTime: 2022-09-14 12:41:25
-->
<template>
  <div class="about_detail">
    <div style="padding: 10px 20px;">
      <div class="oneRow">
        <span class="title">协会电话：</span>
        <span class="context">0592-5720328</span>
      </div>
      <div class="oneRow">
        <span class="title">协会邮箱：</span>
        <span class="context">xhdcia2021@163.com</span>
      </div>
      <div class="oneRow">
        <span class="title">协会微信公众号：</span>
        <span class="context">厦门市湖里区建设行业协会</span>
      </div>
      <div class="oneRow">
        <span class="title">协会地址：</span>
        <span class="context">厦门市湖里区禾山路266号联谊大厦515室</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: "",
      numPages: null,
      htmlContent: ["协会简介", "协会章程", "领导机构", "协会任务"]
    };
  },
  created() {},
  components: {},
  mounted() {},
  computed: {
    routerComputed() {
      return "协会简介";
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.about_detail {
  .oneRow {
    margin-bottom: 24px;
    display: flex;
    font-size: 18px;
    .title {
      color: #666666;
    }
    .context {
      color: #333333;
    }
  }
}
</style>
