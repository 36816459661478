<!--
 * @Author: YeJson
 * @Date: 2021-07-18 09:25:31
 * @LastEditors: 叶锦全
 * @LastEditTime: 2023-06-29 22:56:23
 * @Description: 文件描述
 * @FilePath: \YeesharyMusicc:\Users\a1808\Desktop\Prrogram\my\web_xh_vue\src\components\msgs.vue
-->
<template>
  <div class="xiaxin_msgs">
    <sub-layout-one :navList="navList" :defaultSelect="navList.data[currentId]" @itemSelect="itemSelect"></sub-layout-one>
  </div>
</template>
<script>
import subLayoutOne from './common/subLayoutOne.vue'
export default {
  components: { subLayoutOne },
  data() {
    return {
      currentId: 0,
      navList: {
        title: '新闻中心',
        data: []
      },
      defaultSelect: null
    }
  },
  created() {
    const _that = this
    _that.$axios.get('/info/getIndexNewsData').then((res) => {
      _that.navList.data = Object.values(res.data.data).map((item) => {
        const addObj = {
          name: item.type_name,
          show_sub: false,
          icon: 'appstore',
          path: '/home/msgs/msgs_cards?id=' + item.id
        }
        Object.assign(item, addObj)
        item.sons.forEach((son) => {
          Object.assign(son, {
            path: '/home/msgs/msgs_list?id=' + son.id,
            name: son.type_name
          })
        })
        return item
      })
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    itemSelect(item) {
      console.log(item)
      this.currentId = item.id
    }
  }
}
</script>
